import React from 'react'
import coverStyles from './cover.module.css'


// To use this component, enclose the content in the Cover element. The main
//  vertically centered element can be identified by giving it the
//  'cover-heading' class. Additional elements can be added above and below the
//  heading.

const cover = (props) => {
  // let elementClasses = 'cover'
  // if (props.classes) {
  //   elementClasses += ' ' + props.classes
  // }

  const elementStyle = {
    background: props.backgroundStyle,
    backgroundSize: 'cover',
    minHeight: '75vh'
  }

  return <div className={coverStyles.cover} style={elementStyle}>
    {props.children}
  </div>
}

export default cover