import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Splash from '../components/Splash/Splash'
import ImageTextGroup from '../components/ImageTextGroup/ImageTextGroup'
import groupKiting from '../components/Splash/group-kiting.jpg'
import airushCarve from '../components/Cover/airush-carve.jpg'
import jumpPhoto from '../components/Cover/kiteboarding-jump.jpg'
import islandImage from '../../static/island.jpg'

export default function Credits() {

  const textBlockStyles = {
    flexBasis: 0,
    flexGrow: '999',
    minWidth: 'calc(60% - 1.5rem)',
    padding: '1em',
    alignSelf: 'center'
  }

  return <Layout>
    <SEO title={'Credits'}></SEO>
    <Splash></Splash>
    <p style={{marginBottom: 'var(--s5)'}}>The photos used on this website are listed below with details about the photographer.</p>

    <div style={{ maxWidth: '60em', margin: 'auto' }}>
      <div style={{margin: '1rem'}}>
        <ImageTextGroup image={groupKiting} imagePosition="left">
          <div style={textBlockStyles}>
            Photo by <a href="https://unsplash.com/@marcreation">Marcreation</a><br />
            Location: <em>Sharkbay, Langebaan, Southafrica</em><br />
            Photo URL: <a href="https://unsplash.com/photos/WE7qvb0nqEA">https://unsplash.com/photos/WE7qvb0nqEA</a>
          </div>
        </ImageTextGroup>
      </div>

      <div style={{margin: '1rem'}}>
        <ImageTextGroup image={jumpPhoto} imagePosition="right">
          <div style={textBlockStyles}>
            Photo by <a href="https://unsplash.com/@uniqsurface">uniqsurface</a><br />
            Location: <em>Meon Shore, Titchfield, Fareham, UK</em><br />
            Photo URL: <a href="https://unsplash.com/photos/xvjpZDHoEAo">https://unsplash.com/photos/xvjpZDHoEAo</a>
          </div>
        </ImageTextGroup>
      </div>

      <div style={{margin: '1rem'}}>
        <ImageTextGroup image={airushCarve} imagePosition="left">
          <div style={textBlockStyles}>
            Photo by <a href="https://unsplash.com/@timovoss">Timo Voß</a><br />
            Location: <em>Kos, Greece</em><br />
            Photo URL: <a href="https://unsplash.com/photos/vsUxnpCKTeQ">https://unsplash.com/photos/vsUxnpCKTeQ</a>
          </div>
        </ImageTextGroup>
      </div>

      <div style={{margin: '1rem'}}>
        <ImageTextGroup image={islandImage} imagePosition="right">
          <div style={textBlockStyles}>
            Photo by <a href="https://unsplash.com/@lvnatikk">Lily Banse</a><br />
            Location: <em>Thailand</em><br />
            Photo URL: <a href="https://unsplash.com/photos/WvN244uN8bs">https://unsplash.com/photos/WvN244uN8bs</a>
          </div>
        </ImageTextGroup>
      </div>
    </div>
    
  </Layout>
}