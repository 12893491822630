import React from 'react'

import componentStyles from './imageTextGroup.module.css'
// import groupKiting from '../Splash/group-kiting.jpg'

const ImageTextGroup = (props) => {
  const getContainerStyleClasses = (imagePosition) => {
    // props.imagePosition

    const styles = [componentStyles.container]

    if (imagePosition === 'left') {
      styles.push(componentStyles.imageLeft)
    } else if (imagePosition === 'right') {
      styles.push(componentStyles.imageRight)
    }
    return styles.join(' ')
  }

  return <div className={getContainerStyleClasses(props.imagePosition)}>
    <div>
      <div className={componentStyles.imageContainer}>
        <img src={props.image} />
      </div>
      {props.children}
    </div>
  </div>
}

export default ImageTextGroup
