import React, { useEffect, useState } from 'react'

import Cover from '../Cover/Cover'
// import {coverStyles} from '../Cover/cover.module.css'
import Navbar from '../Navbar/Navbar'
import groupKiting from './group-kiting.jpg'
import splashStyles from './splash.module.css'
import { user$ } from '../../services/auth'


const Splash = () => {
  const backgroundStyle = `
    linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ), url(${groupKiting}) #B7DBCF no-repeat center center fixed
  `

  const [state, setState] = useState({
    user: {}
  })

  useEffect(() => {
    const subscription = user$.subscribe(authUser => {
      setState({user: authUser})
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [state.user])

  return <Cover backgroundStyle={backgroundStyle}>
    <Navbar user={state.user} isLight={true}></Navbar>
    <h1 className={splashStyles.heading}>Can I teach myself kiteboarding?</h1>
  </Cover>
}

export default Splash